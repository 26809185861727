<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                :isGkkpPaid="true"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadRepPaid="downloadRepPaid"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header 
                    ref="budgetHeader" 
                    :form="form" 
                    :yearReq="true" 
                    :openDisabled="openDisabled" 
                    :currTabName="currTabName"
                    @setProgress="setProgress"
                    @chgData="changeHeader" 
                ></budget-header>
            </div>
            <div class="right-content">
                <c-budget-forms-data-transfer
                    v-if="!isGkkpMode && !(progress < 100) && variantAttribute"
                    :header="header"
                    :lng="lng"
                    :getDataTransferText="getDataTransferText"
                    :getErrText="getErrText"
                    :makeToast="makeToast"
                    @reloadTable="loadDatas"
                />
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="isPaidTab ?  budgetFormPaid : budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        :isPaidTab="isPaidTab"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="primary" @click="onAddCategoryClicked" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <b-tabs 
            active-nav-item-class="budget-form_tabs_active_tab"
            nav-class="budget-form_tabs" 
            v-model="activeTabIndex"
        >
            <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>

            <b-progress
                    variant="success"
                    v-show="progress < 100 && progress > 0"
                    height="10px"
                    :value="progress"
                    striped
                    animated
            ></b-progress>
            <b-tab 
                :title="getCommonText('budget_expense')"
                @click="updateRoute(0)" 
            >
                <!-- Первая вклапдка -->
                <form-04-151-tab1  
                    ref="formTab"
                    :budgetForm="budgetForm"
                    :getCommonText="getCommonText"
                    :getDecText="getDecText"
                    :getUnitsText="getUnitsText"
                    :setFormText="setFormText"
                    :variantAttribute="variantAttribute"
                    :total="total"
                    :isLoad="isLoad"
                    @setSelectAll="setSelectAll"
                    @deleteItemWithAttachedFiles="deleteItemWithAttachedFiles"
                    @keyup13="keyup13"
                    @keyPress="keyPress"
                    @inputFixedVldtn="inputFixedVldtn"
                    @onFilesClick="onFilesClick"
                    @addItem="onAddCategoryClicked"
                    @onEditNote="onEditNote"
                    @doublesValidation="doublesValidation"
                ></form-04-151-tab1>

                <!-- Первая вклапдка -->
                <files-updown :header="header"
                            :variant-attribute="variantAttribute"
                            :load="load"
                            @getFiles="getFiles"
                            @getNewFiles="getNewFiles($event)"
                            @delFile="delFile($event)"
                            @onFilePreview="onFilePreview"
                            ref="fileUpdown"
                ></files-updown>

                <!-- Первая вклапдка -->
                <modal-files-management-nodecode
                    ref="modalFilesManagement"
                    :variant-attribute="variantAttribute"
                    @triggDownloadFile="triggDownloadFile"
                    @triggDownloadAllFiles="triggDownloadAllFiles"
                    @fileUpload="fileUpload"
                    @toggleIsAdd="toggleIsAdd($event)"
                    />

                    <modal-note
                        ref="setNoteModal"
                        :noteFieldName="noteFieldName"
                        :getCommonText="getCommonText"
                    />
            </b-tab>
            <b-tab 
                :title="getCommonText('paid_services')" 
                @click="updateRoute(1)"
            >
                <!-- Вторая вклапдка -->
                <form-04-151-tab1  
                    ref="formTabPaid"
                    :budgetForm="budgetFormPaid"
                    :getCommonText="getCommonText"
                    :getDecText="getDecText"
                    :getUnitsText="getUnitsText"
                    :setFormText="setFormText"
                    :variantAttribute="variantAttribute"
                    :total="totalPaid"
                    :isLoad="isLoad"
                    @setSelectAll="setSelectAll"
                    @deleteItemWithAttachedFiles="deleteItemWithAttachedFilesPaid"
                    @keyup13="keyup13"
                    @keyPress="keyPress"
                    @inputFixedVldtn="inputFixedVldtn"
                    @onFilesClick="onFilesClickPaid"
                    @addItem="onAddCategoryClicked"
                    @onEditNote="onEditNotePaid"
                    @doublesValidation="doublesValidation"
                ></form-04-151-tab1>

                <!-- Вторая вклапдка -->
                <files-updown :header="header"
                            :variant-attribute="variantAttribute"
                            :load="load"
                            :isPaidTab="true"
                            @getFiles="getFilesPaid"
                            @getNewFiles="getNewFilesPaid($event)"
                            @delFile="delFilePaid($event)"
                            @onFilePreview="onFilePreview"
                            ref="fileUpdownPaid"
                ></files-updown>

                <!-- Вторая вклапдка -->
                <modal-files-management-nodecode
                    ref="modalFilesManagementPaid"
                    :variant-attribute="variantAttribute"
                    @triggDownloadFile="triggDownloadFilePaid"
                    @triggDownloadAllFiles="triggDownloadAllFilesPaid"
                    @fileUpload="fileUploadPaid"
                    @toggleIsAdd="toggleIsAddPaid($event)"
                    />

                    <modal-note
                        ref="setNoteModalPaid"
                        :noteFieldName="noteFieldName"
                        :getCommonText="getCommonText"
                    />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1Paid from "./mixins/forms-handler-mixin-1-paid";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import Form04151Tab1 from './Form04_151_tab1.vue';
import ModalNote from './components/modal-note.vue';
import CBudgetFormsDataTransfer from './components/budget-forms-data-transfer.vue';

export default {
    name: 'Form04_151',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        BreadcrumbsFilter, 
        ModalFilesManagementNodecode, 
        CBudgetFormsCopyData,
        Form04151Tab1,
        ModalNote,
        CBudgetFormsDataTransfer
    },
    mixins: [FormsHandlerMixin1Paid],
    data() {
        return {
            form: {
                code: '04-151',
                name_ru: 'Расчет расходов тепла на отопление зданий, помещений для государственных учреждений с центральной системой отопления',
                name_kk: 'Орталық жылу жүйесi бар мемлекеттiк мекемелер үшін ғимараттарды, үй-жайларды жылытуға жұмсалатын жылудын шығыстарын есептеу'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            budgetFormPaid: [],
            header: null,
            dictObjects: [],
            dictTariffs: [],
            files: null,
            filesPaid: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            isAdd: false,
            rowId: false,
            rowIdPaid: false,
            newRowStartId: -1,
            noteFieldName: 'note'
        };
    },

    watch: {
        selectAllPaid() {
            this.$refs.formTabPaid.setSelectAll(this.selectAllPaid);
        },

        selectAll() {
            this.$refs.formTab.setSelectAll(this.selectAll);
        }
    },

    methods: {
        addItem(budgetForm) {
            if (this.isDoubleDecodeExist(budgetForm)) return;
            const item = { id: this.newRowStartId };
            this.newRowStartId--;
            this.itemUpdate(item);

            this.defineCalcProps(item);
            
            budgetForm.push(item);
        },

        defineCalcProps(item) {
            Object.defineProperty(item, 'amount', {
                get: () => {
                    const area = this.safeDecimal(item.area);
                    const costAvg = this.safeDecimal(item.cost_avg);
                    const amoutRounded = area.mul(costAvg).toDecimalPlaces(2).toNumber();
                    return amoutRounded;
                }
            });
            Object.defineProperty(item, 'total', {
                get: () => {
                    const amount = this.safeDecimal(item.amount);
                    const season = this.safeDecimal(item.season);
                    const corrFactors = this.safeDecimal(item.correction_factors)
                    const totalRounded = amount.mul(season).div(1000).add(corrFactors).toDecimalPlaces(2).toNumber();
                    return totalRounded;
                }
            });
        },

        async itemUpdate(item) {
            this.$set(item, 'area', 0);
            this.$set(item, 'cost_avg', 0);
            this.$set(item, 'season', 1);
            this.$set(item, 'correction_factors', 0);
            this.$set(item, 'note', '');
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'isHasDouble', false);

            this.$set(item, 'files', 0);

            try {
                this.load = true;
                let url = `/api-py/get-new-row-files-form/${item.id}/`;
                if (this.activeTabIndex === 1) url = `/api-py/get-new-row-files-form-paid/${item.id}/`;
                const response = await fetch(url + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
            }
            this.load = false;
        },

        getFiles(data) {
            this.files = data;
        },
        getFilesPaid(data) {
            this.filesPaid = data;
        },

        async loadDatas() {
            this.load = true;
            this.budgetForm.splice(0);
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                if (response.status === 200) {
                    values = await response.json();
                    this.fillBudgetForm(values, this.budgetForm);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas`, error.toString());
            }
            this.load = false;
        },

        async loadDatasPaid() {
            this.load = true;
            this.budgetFormPaid.splice(0);
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files-paid/' + JSON.stringify(this.header));
                if (response.status === 200) {
                    values = await response.json();
                    this.fillBudgetForm(values, this.budgetFormPaid);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatasPaid`, error.toString());
            }
            this.load = false;
        },

        fillBudgetForm(values, budgetForm) {
            values.forEach(val => {
                    const item = {
                        id: val.id,
                        area: val.area,
                        cost_avg: val.cost_avg,
                        files: val.files,
                        row_files: val.row_files,
                        itemToDelete: false,
                        season: val.season,
                        isHasDouble: false,
                    };
                    val.correction_factors ? this.$set(item, 'correction_factors', val.correction_factors)
                        : this.$set(item, 'correction_factors', 0);
                    val.note ? this.$set(item, 'note', val.note)
                        : this.$set(item, 'note', '');
                    
                    this.defineCalcProps(item);

                    budgetForm.push(item);
                });
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        number(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        prepareForSave() {
            const budgetForm = this.curBudgetForm;
            if (this.isDoubleDecodeExist(budgetForm)) {
                this.makeToast('warning', this.getErrText('not_saved'), this.getErrText('not_valid'));
                return;
            }
            this.isFetch = true;
            const values = [];
            let error = false;
            for (const row of budgetForm) {
                if (this.isSavingForbidden(row, this.$refs.formTabPaid.tableFields, ['area', 'cost_avg', 'season'], [])) return;
                if (!(row.season > 12)) {
                    if ((row.correction_factors === 0 && (row.note === null || row.note.length < 256)) || (row.correction_factors !== 0 && row.note.trim().length !== 0 && row.note.length < 256)) {
                        const item = Object.assign({}, this.header);
                        this.$set(item, 'id', row.id);
                        this.$set(item, 'area', row.area);
                        this.$set(item, 'cost_avg', parseFloat(row.cost_avg));
                        this.$set(item, 'season', parseFloat(row.season));
                        this.$set(item, 'correction_factors', parseFloat(row.correction_factors));
                        this.$set(item, 'note', row.note);
                        this.$set(item, 'row_files', row.row_files);
                        this.$set(item, 'total', row.total);
                        values.push(item);
                    }
                    else {
                        error = true;
                    }
                } else {
                    error = true;
                }
            }
            if (error) {
                this.onFieldsValidationFailed();
                return;
            }
            if (values.length > 0 && this.variantAttribute) {
                if (this.activeTabIndex === 0) {
                    this.save(values, error) 
                } else { 
                    this.savePaid(values, error);
                }
            } else {
                this.isFetch = false;
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        }, // подготовка к сохранению

        async save(values, error) {
            this.isLoad = true;
            try {
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : '/api-py/save-brform' + this.form.code + '/'
                const totalToSave = this.totalCalculation(values, 'total')
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async savePaid(values, error) {
            this.isLoad = true;
            try {
                const url = '/api-py/save-brform-gkkp-paid/';
                const totalToSave = this.totalCalculation(values, 'total')
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.filesPaid.length === 0) {
                        this.makeToast('danger', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    await this.loadDatasPaid();
                } else {
                    await this.loadDatasPaid();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        doublesValidation(budgetForm) {
            const doublesMap = {};
            this.$nextTick(() => {
                budgetForm.forEach(item => {
                    const area = item.area ? item.area : 0;
                    const costAvg = item.cost_avg ? item.cost_avg : 0;
                    
                    if (area && costAvg) {
                        const mapKey = `${area}_${costAvg}`;
                        if (doublesMap[mapKey]) {
                            doublesMap[mapKey].push(item.id);   
                        } else {
                            doublesMap[mapKey] = [item.id];
                        }
                    }
                })
                const doubleIdsList = []
                for (const key in doublesMap) {
                    const idsList = doublesMap[key];
                    const isKeyHasDoubles = idsList.length > 1;
                    if (isKeyHasDoubles) {
                        doubleIdsList.push(...idsList);
                    }
                }

                if (doubleIdsList.length > 0) {
                    budgetForm.forEach(item => {
                        if (doubleIdsList.includes(item.id)) {
                            this.$set(item, 'isHasDouble', true);
                        } else {
                            this.$set(item, 'isHasDouble', false);
                        }
                    })
                } else (
                    budgetForm.forEach(item => this.$set(item, 'isHasDouble', false))
                )
            })
        },

        afterDeleted() {
            this.doublesValidation(this.curBudgetForm);
        },
        afterDeletedPaid() {
            this.doublesValidation(this.curBudgetForm);
        }
    },
    computed: {
        total() {
            return this.totalCalculation(this.budgetForm, 'total');
        },
        totalPaid() {
            return this.totalCalculation(this.budgetFormPaid, 'total');
        },
        curBudgetForm() {
            return this.activeTabIndex === 0 ? this.budgetForm : this.budgetFormPaid;
        }
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
    .budget-form_tabs {
        display: none;
    }
    
</style>